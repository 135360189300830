import React, { useState, useEffect, useRef, lazy, Suspense } from "react";

import { format } from "date-fns";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// Definição de constantes para os tempos em milissegundos
const timeObject = {
  "6 Horas": 60 * 1000 * 60 * 6,
  "12 Horas": 60 * 1000 * 60 * 12,
  "16 Horas": 60 * 1000 * 60 * 16,
  "1 Dia": 60 * 1000 * 60 * 24,
};

let orderloop = [
  "first_home_bet",
  "second_home_bet",
  "third_home_bet",
  "fourth_home_bet",
  "fifth_home_bet",
  "sixth_home_bet",
  "seventh_home_bet",
  "eighth_home_bet",
  "ninth_home_bet",
  "tenth_home_bet",
];

// Lista de sites de apostas que podem ser filtrados
const listBethomeChangeUrl = ["pinnacle"];
//import FilterMobile from "./FilterMobile";
const FilterMobile = lazy(() => import("./FilterMobile"));
// Dicionário de links alternativos para sites de apostas
const alternateLinkBetHome = { pinnacle: "http://bit.ly/arbpinnacle" };

function MainBets(props) {
  // Desestruturação de props
  const {
    valueMin,
    valueMax,
    betHome,
    sports,
    fullData,
    setFullData,
    timeEvent,
    itemsTrash,
    setItemsTrash,
    statusMode,
    controllerLocalStorage,
    setValueMin,
    setValueMax,
    setBetHome,
    quantityBetHomeEnabled,
    setQuantityBetHomeEnabled,
    setSports,
    quantitySportsEnabled,
    setQuantitySportsEnabled,
    setTimeEvent,
    notificationVolume,
    setNotificationVolume,
    setPopupWindow,
    popupWindow,
    setPopupWindowBet,
    popuwindowsBet,
    isLoggedIn,
  } = props;
  const iconelixeira = `${process.env.PUBLIC_URL}/images/iconlixeira.png`;
  // Definição de estados
  const [datacalculator, setDatacalculator] = useState(
    JSON.parse(localStorage["dataCalculator"])
  );
  const [checkUpdate, setCheckUpdate] = useState("1");
  const [checkOpen, setCheckOpen] = useState("1");
  const [urls, setUrls] = useState("Vazio");
  const [urls2, setUrls2] = useState("Vazio");
  const [varurls, setvarUrls] = useState("1");
  const [data, setData] = useState([]);
  const [newKeySymbol, setNewKeys] = useState([]);
  const oldData = useRef({});
  const [statusTrash, setStatusTrash] = useState(false);
  const [colunaAtiva, setColunaAtiva] = useState("preEvento"); // Inicialmente, definimos a coluna pré-evento como ativa
  const toggleColuna = (coluna) => {
    setColunaAtiva(coluna);
  };
  const [notificationTime, setNotificationTime] = useState(null);

  // Lista para rastrear IDs das apostas já notificadas
  const notifiedBetIds = useRef([]);
  const [playSound, setPlaySound] = useState(false);

  // Função para verificar se uma aposta está habilitada e notificar apenas apostas novas
  function checkBetHomeEnabledAndNotify(game) {
    const betHomeEnabled = JSON.parse(localStorage.listBehomeEnabled);

    for (
      let indexOrderLoop = 0;
      indexOrderLoop < orderloop.length;
      indexOrderLoop++
    ) {
      let keyOrderBethome = orderloop[indexOrderLoop];
      if (keyOrderBethome in game) {
        let betHomeIsEnable = betHomeEnabled.includes(
          game[keyOrderBethome]["name_bet_home"].toLowerCase()
        );

        
        if (betHomeIsEnable === false) {
          return false;
        }
      }
    }
    notifyBet(game);
    return true;
  }

  // Função para notificar a aposta
  function notifyBet(bet) {
    setPlaySound(true);
    // Lógica para exibir a notificação aqui
    // Você pode personalizar a notificação de acordo com suas necessidades
    const song = `${process.env.PUBLIC_URL}apitodefutebol.mp3`;
    const audioElement = new Audio(song);

    const playNotificationSound = () => {
      // Defina o volume do áudio com base no estado notificationVolume
      audioElement.volume = notificationVolume;

      audioElement.play().catch((error) => {
        console.error("Erro ao reproduzir o som:", error);
      });
    };

    if (playSound) {
      playNotificationSound();
      const title = "ARBITRAGEM.BET";
      const msg = `2 Novas Oportunidades de Arbritagem -2`;
      const icon = `${process.env.PUBLIC_URL}/favicon.ico`;

      if ("Notification" in window) {
        const currentTime = format(new Date(), "HH:mm:ss");

        setNotificationTime(currentTime);
      }
      setPlaySound(false);
    }
  }
  // Função para abrir uma janela pop-up do calculadora
  function openPopUpCalculator(value) {
    const url = `/#/calculator/${value}`;
    const windowName = "";

    // Verifique se a janela pop-up está aberta ou se é a primeira vez
    if (popupWindow && !popupWindow.closed) {
      const urls = `/calculator/${value}`;
      // Se estiver aberta, atualize o URL da janela existente

      // Adicione um manipulador de eventos para recarregar a página quando o hash do URL for alterado
      popupWindow.onhashchange = function () {
        popupWindow.location.reload();
      };
      popupWindow.location.hash = urls;
      return true;
    } else {
      // Se não estiver aberta, abra uma nova janela pop-up
      const windowFeatures = "width=800,height=460";

      setPopupWindow(window.open(url, windowName, windowFeatures));
      return true;
    }
    return true;
  }

  function openPopUpBet(value) {
    const url = `${value}`;
    const windowName = "popupWindow"; // Nome da janela pop-up
    const windowName2 = "popupWindow2"; // Nome da janela pop-up

    // Verifique se a janela pop-up já existe
    if (
      window[windowName] &&
      !window[windowName].closed &&
      window[windowName2] &&
      !window[windowName2].closed
    ) {
      // Atualize a URL da janela pop-up existente
      if (checkUpdate == "1") {
        window[windowName].location.href = url;
        setCheckUpdate("2");
      } else {
        window[windowName2].location.href = url;
        setCheckUpdate("1");
      }
    } else {
      if (checkOpen == "1") {
        // Se não houver uma janela pop-up aberta, abra uma nova
        setCheckOpen("2");
        const windowFeatures = "width=800,height=460";
        window[windowName] = window.open(url, windowName, windowFeatures);
      }
      if (checkOpen == "2") {
        setCheckOpen("1");
        // Se não houver uma janela pop-up aberta, abra uma nova
        const windowFeatures = "width=800,height=460";
        window[windowName2] = window.open(url, windowName2, windowFeatures);
      }
    }
  }
  function openPopUpAllBet(value, value2) {
    const url = `${value}`;
    const url2 = `${value2}`;
    const windowName = "popupWindowAll"; // Nome da janela pop-up
    const windowName2 = "popupWindowAll2"; // Nome da janela pop-up

    // Verifique se a janela pop-up já existe
    if (
      window[windowName] &&
      !window[windowName].closed &&
      window[windowName2] &&
      !window[windowName2].closed
    ) {
      window[windowName].location.href = url;
      window[windowName2].location.href = url2;
      return;
    } else {
      const windowFeatures = "width=800,height=460";
      const windowFeatures2 = "width=800,height=460";
      window[windowName] = window.open(url, windowName, windowFeatures);

      window[windowName2] = window.open(url2, windowName2, windowFeatures2);
      return;
    }
  }
  // Função para buscar dados da API
  const fetchData = async () => {
    if (oldData.current.value === undefined) {
      oldData.current.value = "{}";
    }

    try {
      const response = await axios.get(
        "https://arbitragem.vps.webdock.cloud/safe_bet"
      );
      //const response = await fetch(
      // "https://arbitragem.vps.webdock.cloud/safe_bet"
      //);

      if (!response.status) {
        throw new Error("Falha na requisição");
      }

      const json = await response.data;

      if (JSON.stringify(json) === JSON.stringify(oldData.current.value)) {
        return; // Não há mudanças nos dados, saia da função
      }

      const dataJsonConvertted = JSON.parse(json);
      const oldDataJsonConvertted = JSON.parse(oldData.current.value);

      const newKeys = Object.keys(dataJsonConvertted);
      const oldKeys = Object.keys(oldDataJsonConvertted);
      
      const storageKeys = newKeys.filter(
        (valueKey) => !oldKeys.includes(valueKey)
      );
      const listKeysToRemove = oldKeys.filter(
        (valueKey) => !newKeys.includes(valueKey)
      );

      listKeysToRemove.forEach((key) => {
        delete oldDataJsonConvertted[key];
      });

      storageKeys.forEach((key) => {
       
        oldDataJsonConvertted[key] = dataJsonConvertted[key];
        // Verificar se o navegador suporta notificações
        notifyBet(key);
        
      });

      const keysBet = Object.keys(oldDataJsonConvertted);
      for (const key of keysBet) {
        const dataBet = oldDataJsonConvertted[key]["main"];
        const allTime = [];

        for (const order of orderloop) {
          if (order in dataBet) {
            allTime.push(Number(dataBet[order]["time_milliseconds"]));
          }
        }

        let statusLoop = true;
        for (let indexTime = 0; indexTime < allTime.length; indexTime++) {
          const time = allTime[indexTime];
          for (let indexTime2 = 0; indexTime2 < allTime.length; indexTime2++) {
            const time2 = allTime[indexTime2];
            const resultCalculate = time - time2;
            if (resultCalculate > 900000 || resultCalculate < -900000) {
              oldDataJsonConvertted[key]["main"]["bgTime"] = true;
              statusLoop = false;
              break;
            }
          }
          if (!statusLoop) {
            break;
          }
        }
      }

      setFullData(oldDataJsonConvertted);
      oldData.current.value = json;

      setNewKeys((prevArray) => [...prevArray, ...storageKeys]);

      setTimeout(() => {
        setNewKeys((keys) =>
          keys.filter((item) => !storageKeys.includes(item))
        );
      }, 10000);
    } catch (error) {
      console.error("Erro na requisição: " + error.message);
    }
  };

  // Função para verificar se uma aposta está habilitada
  function checkBetHomeEnabled(game) {
    const betHomeEnabled = JSON.parse(localStorage.listBehomeEnabled);

    for (
      let indexOrderLoop = 0;
      indexOrderLoop < orderloop.length;
      indexOrderLoop++
    ) {
      let keyOrderBethome = orderloop[indexOrderLoop];
      if (keyOrderBethome in game) {
        let nameBetHome = game[keyOrderBethome]["name_bet_home"].toLowerCase();

        // Verifique se a Betboo está habilitada nas configurações
        if (betHomeEnabled.includes("betboo")) {
          // Verifique se o nome da casa de apostas é Betboo e, em seguida, faça as alterações necessárias
          if (nameBetHome === "betboo") {
            game[keyOrderBethome]["name_bet_home"] = "Betboo"; // Altere o nome para "Betboo"
            game[keyOrderBethome]["url_bet_home"] = game[keyOrderBethome][
              "url_bet_home"
            ].replace(".sportingbet.", ".br.betboo.");
            game[keyOrderBethome]["url_bet_home"] = game[keyOrderBethome][
              "url_bet"
            ].replace(".sportingbet.", ".br.betboo."); // Altere a URL para Betboo
          }
        }
        // Verifique se a Sportingbet está habilitada nas configurações
        else if (betHomeEnabled.includes("sportingbet")) {
          // Verifique se o nome da casa de apostas é Sportingbet e, em seguida, faça as alterações necessárias
          if (nameBetHome === "betboo") {
            game[keyOrderBethome]["name_bet_home"] = "Sportingbet"; // Altere o nome para "Sportingbet"

            game[keyOrderBethome]["url_bet_home"] = game[keyOrderBethome][
              "url_bet_home"
            ].replace(".br.betboo.", ".sportingbet.");
            game[keyOrderBethome]["url_bet_home"] = game[keyOrderBethome][
              "url_bet"
            ].replace(".br.betboo.", ".sportingbet."); // Altere a URL para Sportingbet
          }
        }
        // Verifique se a Bwin está habilitada nas configurações
        else if (betHomeEnabled.includes("bwin")) {
          // Verifique se o nome da casa de apostas é Bwin e, em seguida, faça as alterações necessárias
          if (nameBetHome === "betboo") {
            game[keyOrderBethome]["name_bet_home"] = "Bwin"; // Altere o nome para "Bwin"

            game[keyOrderBethome]["url_bet_home"] = game[keyOrderBethome][
              "url_bet_home"
            ].replace(".br.betboo.", ".bwin.");
            game[keyOrderBethome]["url_bet_home"] = game[keyOrderBethome][
              "url_bet"
            ].replace(".br.betboo.", ".bwin."); // Altere a URL para Bwin
          }
        }

        // Verifique se a casa de apostas atual está habilitada nas configurações
        let betHomeIsEnable = betHomeEnabled.includes(nameBetHome);
        if (betHomeIsEnable === false) {
          return false;
        }
      }
    }
    return true;
  }

  // Função para verificar a quantidade de apostas habilitadas
  function checkQuantityBetEnabled(hideBets, filterBetHome) {
    let countBetHide = -1;
    let keySurebet = Object.keys(hideBets);
    for (
      let indexhideBets = 0;
      indexhideBets < keySurebet.length;
      indexhideBets++
    ) {
      let hideBet = hideBets[keySurebet[indexhideBets]];

      countBetHide += checkBetHomeEnabled(hideBet["main"]) === true ? 1 : 0;
    }
    return countBetHide;
  }

  // Função para gerenciar a exibição de apostas
  function managerBetView(fullGame, gameMain = []) {
    let listGames = gameMain.length > 0 ? gameMain : fullGame["group_events"];
    let resultBetHomeChecked = checkBetHomeEnabled(fullGame["main"]);
    let quantityHideBet = 0;
    let textHideBet = "";
    for (
      let indexhideBets = 0;
      indexhideBets < listGames.length;
      indexhideBets++
    ) {
      let hideBet = listGames[indexhideBets]["main"];
      resultBetHomeChecked = checkBetHomeEnabled(hideBet);
      if (resultBetHomeChecked) {
        quantityHideBet = checkQuantityBetEnabled(fullGame["group_events"]);
        if (quantityHideBet > 0) {
          textHideBet = `+ ${quantityHideBet} aposta segura para este evento`;
        }

        fullGame["main"] = hideBet;
        fullGame["main"]["similar_event"] = textHideBet;
        return fullGame;
      }
    }
    return {};
  }

  // Função para exibir apostas em casa
  function betHomeView(fullGame) {
    let bet = managerBetView(fullGame, [fullGame]);
    if (Object.keys(bet).length === 0) {
      bet = managerBetView(fullGame, []);
    }
    return bet;
  }

  // Efeito para filtrar e ordenar dados
  useEffect(() => {
    fetchData();

    setInterval(() => {
      fetchData();
    }, 2500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newValueData = {};

    if (Object.keys(fullData).length > 0) {
      for (let keyObject in fullData) {
        let valueObject = fullData[keyObject];

        let newValueDataObject = betHomeView(valueObject);

        if (Object.keys(newValueDataObject).length === 0) {
          continue;
        }

        // Remova a lógica de filtragem com base na porcentagem de lucro
        // Mantenha todas as apostas, mesmo que tenham a mesma porcentagem de lucro
        let profit = parseFloat(
          newValueDataObject["main"]["profit"]
            .replace("%", "")
            .replace(",", ".")
        );
        if (!(valueMin <= profit && profit <= valueMax)) {
          continue;
        } else if (
          !JSON.parse(localStorage.listSportsEnabled).includes(
            newValueDataObject["main"]["first_home_bet"]["sport"].toLowerCase()
          )
        ) {
          continue;
        } else if (timeEvent !== "Qualquer Horário") {
          let jumpLoop = false;
          for (let i = 0; i < orderloop.length; i++) {
            let order = orderloop[i];

            if (order in newValueDataObject["main"]) {
              let timeGame = parseInt(
                newValueDataObject["main"][order]["time_milliseconds"]
              );
              let timeCalculated = timeGame - new Date().getTime();

              if (timeCalculated >= timeObject[timeEvent]) {
                jumpLoop = true;
                break;
              }
            }
          }
          if (jumpLoop) {
            continue;
          }
        }
        if (itemsTrash.includes(keyObject) === !statusTrash) {
          continue;
        }
        // Verifique se a aposta é habilitada e notificada
        let check = checkBetHomeEnabledAndNotify(newValueDataObject["main"]);
        if (!check) {
          break;
        }
        newValueData[keyObject] = newValueDataObject;
      }

      let newObjectOrdenated = {};
      let objectKeyProfit = {};
      let listProfitToOrdenate = [];
      let keysObject = Object.keys(newValueData);

      for (let i = 0; i < keysObject.length; i++) {
        let keyObject = newValueData[keysObject[i]]["main"]["profit"]
          .replaceAll(",", ".")
          .replaceAll("%", "");
        objectKeyProfit[Number(keyObject).toString()] = keysObject[i];
        listProfitToOrdenate.push(Number(keyObject));
      }

      listProfitToOrdenate.sort(function (a, b) {
        // Ordena pelo lucro, mas se forem iguais, mantém a ordem original.
        if (a === b) {
          return 0; // Mantém a ordem original.
        }
        return a > b ? -1 : 1; // Ordena por lucro.
      });

      for (let i = 0; i < listProfitToOrdenate.length; i++) {
        let keyValueProfit = listProfitToOrdenate[i].toString();
        newObjectOrdenated[objectKeyProfit[keyValueProfit]] =
          newValueData[objectKeyProfit[keyValueProfit]];
      }

      // Atualiza o estado com os dados filtrados e ordenados
      setData(newObjectOrdenated);
    }
    localStorage.setItem("trash", JSON.stringify(itemsTrash));
  }, [
    fullData,
    valueMin,
    valueMax,
    betHome,
    sports,
    timeEvent,
    itemsTrash,
    statusTrash,
  ]);

  // Função para gerenciar a lixeira de eventos
  function eventTrash(nameEvente, statusEvent) {
    if (!statusEvent) {
      setItemsTrash((current) => [...current, nameEvente]);
    } else {
      setItemsTrash(itemsTrash.filter((item) => item !== nameEvente));
    }
  }
  // INICIO DO SOM DO SITE =============================#########################===================================

  function handleClick(value) {
    // ... Outras lógicas ...
    const betHomeEnabled = JSON.parse(localStorage.listBehomeEnabled);
    console.log("Entrou sem charm corretamente");
    try {
      let endereco = "";
      let endereco2 = "";
      let verUrls = "1";

      // Loop para processar URLs
      orderloop.map((newNameOrder, newIndexOrder) => {
        if (newNameOrder in data[value]["main"]) {
          let url = data[value]["main"][newNameOrder]["url_bet"];

          // Verifica e modifica a URL
          if (url.includes("/en/")) {
            url = url.replace("/en/", "/pt/");
          }
          // Verifique se a Betboo está habilitada nas configurações
          if (betHomeEnabled.includes("betboo")) {
            url = url.replace(".sportingbet.", ".br.betboo."); // Altere a URL para Betboo
          }
          // Verifique se a Sportingbet está habilitada nas configurações
          else if (betHomeEnabled.includes("sportingbet")) {
            url = url.replace(".br.betboo.", ".sportingbet."); // Altere a URL para Sportingbet
          }
          // Verifique se a Bwin está habilitada nas configurações
          else if (betHomeEnabled.includes("bwin")) {
            url = url.replace(".br.betboo.", ".bwin."); // Altere a URL para Bwin
          }

          // Define endereco e endereco2 com base na condição
          if (verUrls === "1") {
            verUrls = "2";
            endereco = url;
          } else {
            verUrls = "1";
            endereco2 = url;
          }
        }
        return null;
      });

      // Abre a segunda janela pop-up
      openPopUpAllBet(endereco, endereco2);
      // Abre a primeira janela pop-up
      openPopUpCalculator(value);
    } catch (error) {
      console.error("Ocorreu um erro:", error);
    }
  }

  // FIM DO SOM DO SITE =============================#########################===================================

  const navigate = useNavigate();
  useEffect(() => {
    console.log("Hora da última notificação:", notificationTime);

    if (!isLoggedIn) {
      navigate("/");
    }
  }, [notificationTime, notificationVolume, isLoggedIn]);

  return (
    <div
      className={`fontsizemainbet w-100 border border-2 mt-3 ${
        statusMode ? "borderColor" : ""
      } rounded p-0 d-block mx-2`}
    >
      <Row>
        <Col className="borderstyle">
          <div
            className={`py-2 border-bottom ${
              statusMode ? "borderColor textGray" : ""
            } fw-bold text-center `}
            onClick={() => toggleColuna("preEvento")}
          >
            <button
              className={`text-title-apostas py-2 border-bottom ${
                statusMode ? "borderColor textGray" : ""
              } fw-bold text-center `}
              onClick={() => toggleColuna("preEvento")}
            >
              ARBITRAGENS PRÉ-EVENTO
            </button>
          </div>
        </Col>
        <Col className="borderstyle">
          <div
            className={`py-2 border-bottom ${
              statusMode ? "borderColor textGray" : ""
            } fw-bold text-center `}
            onClick={() => toggleColuna("eventoAoVivo")}
          >
            <button
              className={`text-title-apostas py-2 border-bottom ${
                statusMode ? "borderColor textGray" : ""
              } fw-bold text-center `}
              onClick={() => toggleColuna("eventoAoVivo")}
            >
              ARBITRAGENS AO VIVO
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        {colunaAtiva === "preEvento" && (
          <div>
            <Col>
              {/* Inicio Calculadore/Lideira e Filtro*/}
              <div
                className="py-3 d-flex justify-content-between align-items-center"
                style={{ backgroundColor: statusMode ? "#191c1f" : "#eceff3" }}
              >
                <div className="d-flex">
                  <button
                    type="button"
                    className="h-50 mx-3 d-flex justify-content-center align-items-center btn btn-warning btn-sm fontSizeDefaultDevice"
                    onClick={() => {
                      window.open("/#/calculator/", "", "width=800,height=460");
                    }}
                  >
                    <p className="calculatorrespon">Calculadora</p>
                    <img
                      className="img-listagem-topo"
                      width="25"
                      height="25"
                      src="https://img.icons8.com/color/48/calculator--v1.png"
                      alt="calculator--v1"
                    />
                  </button>
                  <p className="m-0"></p>

                  <button
                    type="button"
                    className={`h-50 btn ${
                      statusTrash ? "btn-primary" : "btn-danger"
                    } btn-sm`}
                  >
                    <div
                      className="d-flex justify-content-center align-items-center"
                      onClick={() => setStatusTrash(!statusTrash)}
                    >
                      {statusTrash ? (
                        <>
                          <img
                            style={{
                              filter: "invert(100%)",
                              width: 15,
                              height: 15,
                            }}
                            src="https://img.icons8.com/ios-filled/50/000000/left2.png"
                            alt="left2"
                          />
                          <p className="m-0 fontSizeDefaultDevice">
                            Voltar aos jogos
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="m-0 fontSizeDefaultDevice calculatorrespon">
                            Lixeira
                          </p>
                          <img
                            className="img-listagem-topo"
                            width="27"
                            height="27"
                            src={iconelixeira}
                            alt="delete"
                          />
                        </>
                      )}
                    </div>
                  </button>

                  <button
                    id="back_game"
                    type="button"
                    className="h-50 btn btn-sm btn-primary d-none"
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        style={{
                          filter: "invert(100%)",
                          width: 25,
                          height: 25,
                        }}
                        src="https://img.icons8.com/ios-filled/50/000000/left2.png"
                        alt="left2"
                      />
                      <p className="m-0">Voltar aos jogos</p>
                    </div>
                  </button>
                </div>

                <div className="me-3">
                  <Suspense fallback={<div>Loading...</div>}>
                    <FilterMobile
                      statusMode={statusMode}
                      controllerLocalStorage={controllerLocalStorage}
                      valueMin={valueMin}
                      setValueMin={setValueMin}
                      valueMax={valueMax}
                      setValueMax={setValueMax}
                      betHome={betHome}
                      setBetHome={setBetHome}
                      quantityBetHomeEnabled={quantityBetHomeEnabled}
                      setQuantityBetHomeEnabled={setQuantityBetHomeEnabled}
                      sports={sports}
                      setSports={setSports}
                      quantitySportsEnabled={quantitySportsEnabled}
                      setQuantitySportsEnabled={setQuantitySportsEnabled}
                      timeEvent={timeEvent}
                      setTimeEvent={setTimeEvent}
                    />
                  </Suspense>
                </div>
              </div>
              {/* Fim Calculadore/Lideira e Filtro*/}
              {/* Inicio Corpo da Tabela */}
              {Object.keys(data).length > 0 || statusTrash ? (
                <Table
                  responsive
                  size="sm"
                  variant="dark"
                  className="w-100"
                  id="table-dados"
                >
                  <thead className="">
                    <tr className=" greenArbitragem py-1">
                      <th
                        className={`fw-bold fontSizeDefaultDevice text-center ${
                          statusMode ? "textDark" : "text-light"
                        } p-0 profitDesktop`}
                        colSpan={2}
                      >
                        Lucro
                      </th>
                      <th
                        className={`fw-bold fontSizeDefaultDevice text-center ${
                          statusMode ? "textDark" : "text-light"
                        } p-0 profitMobile`}
                      >
                        Lucro
                      </th>
                      <th
                        className={`fw-bold fontSizeDefaultDevice text-start ${
                          statusMode ? "textDark" : "text-light"
                        } betHome profitDesktop`}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Casa de aposta
                      </th>
                      <th
                        className={`fw-bold fontSizeDefaultDevice text-center ${
                          statusMode ? "textDark" : "text-light"
                        } p-0`}
                      >
                        Data
                      </th>
                      <th
                        className={`fw-bold fontSizeDefaultDevice text-start ${
                          statusMode ? "textDark" : "text-light"
                        } p-0`}
                      >
                        Evento
                      </th>
                      <th
                        className={`fw-bold fontSizeDefaultDevice text-start ${
                          statusMode ? "textDark" : "text-light"
                        } p-0`}
                      >
                        Mercado
                      </th>
                      <th
                        className={`fw-bold fontSizeDefaultDevice text-start ${
                          statusMode ? "textDark" : "text-light"
                        } p-0`}
                      >
                        Chance
                      </th>
                      <th
                        className={`fw-bold fontSizeDefaultDevice text-start ${
                          statusMode ? "textDark" : "text-light"
                        } p-0`}
                      ></th>
                    </tr>
                  </thead>

                  {Object.keys(data).map((value, index) => (
                    <tbody
                      className={`w-100 ${
                        statusMode
                          ? "bgDark"
                          : index % 2 === 0
                          ? "backgroundstMobileLight"
                          : "backgroundstMobileLight2"
                      } `}
                      key={index}
                    >
                      <tr className="align-end ">
                        <td className="p-0" colSpan={10}>
                          <div
                            className={`d-flex justify-content-between profitTrashCalculatorMobile ${
                              statusMode
                                ? "backgroundProfitMobileDark"
                                : index % 2 === 0
                                ? "backgroundProfitMobileLight"
                                : "backgroundProfitMobileLight2"
                            }`}
                          >
                            <div className="d-flex align-items-end">
                              <p
                                className="m-0 text-success fw-bold fs-5 profit text-center"
                                style={{ whiteSpace: "break-spaces" }}
                              >
                                {newKeySymbol.includes(value) ? (
                                  <>
                                    {data[value]["main"]["profit"]}{" "}
                                    <sup className="text-warning">New</sup>
                                  </>
                                ) : (
                                  <>{data[value]["main"]["profit"]}</>
                                )}
                              </p>

                              <div className="d-flex align-items-end">
                                <span
                                  className={`ps-1 m-0 legenda ${
                                    statusMode ? "textGray1" : "text-black-50"
                                  } `}
                                  style={{ height: "1.79574vw" }}
                                >
                                  {data[value]["main"]["time"]}
                                </span>
                              </div>
                            </div>

                            <div className="d-flex justify-content-center">
                              <button
                                className="p-0 mx-1 btn btn-warning text-decoration-none d-flex justify-content-center align-items-center defaultSizeBtnTrashCalculatorDevice"
                                title="Calculadora de aposta segura"
                                onClick={() => {
                                  openPopUpCalculator(value);
                                }}
                                style={{ width: "25px", height: "25px" }}
                              >
                                <img
                                  className="img-listagem"
                                  width="20"
                                  height="20"
                                  src="https://img.icons8.com/color/48/calculator--v1.png"
                                  alt="calculator--v1"
                                />
                              </button>
                              <button
                                type="button"
                                className={`all_trash p-0  d-flex justify-content-center align-items-center btn ${
                                  statusTrash ? "btn-primary" : "btn-danger"
                                } defaultSizeBtnTrashCalculatorDevice`}
                                style={{ width: "25px", height: "25px" }}
                                onClick={() => eventTrash(value, statusTrash)}
                              >
                                {statusTrash ? (
                                  <>
                                    <img
                                      className="img-listagem-voltar"
                                      style={{
                                        filter: "invert(100%)",
                                        width: 16,
                                        height: 16,
                                      }}
                                      src="https://img.icons8.com/ios-filled/50/000000/left2.png"
                                      alt="left2"
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      className="img-listagem"
                                      width="25"
                                      height="25"
                                      src={iconelixeira}
                                      alt="delete"
                                    />
                                  </>
                                )}
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>

                      <tr className="align-top mt-2 profitDesktop">
                        <td
                          className="ps-1 text-center padding_top profitTime"
                          rowSpan="5"
                          colSpan="2"
                          style={{ lineHeight: "16px" }}
                        >
                          <p
                            className="m-0 mt-3 pb-0  text-success fw-bold fs-5 profit d-flex justify-content-center text-center"
                            style={{ whiteSpace: "break-spaces" }}
                          >
                            {newKeySymbol.includes(value) ? (
                              <>
                                {data[value]["main"]["profit"]}{" "}
                                <sup className="text-warning">New</sup>
                              </>
                            ) : (
                              <>{data[value]["main"]["profit"]}</>
                            )}
                          </p>

                          <span
                            className={`m-0 legenda ${
                              statusMode ? "textGray1" : "text-black-50"
                            } `}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {data[value]["main"]["time"]}
                          </span>
                          <div className="d-flex justify-content-center">
                            <button
                              className="p-0 m-1 btn btn-warning text-decoration-none d-flex justify-content-center align-items-center defaultSizeBtnTrashCalculatorDevice"
                              title="Calculadora de aposta segura"
                              onClick={() => {
                                openPopUpCalculator(value);
                              }}
                              style={{ width: "25px", height: "25px" }}
                            >
                              <img
                                className="img-listagem"
                                width="25"
                                height="25"
                                src="https://img.icons8.com/color/48/calculator--v1.png"
                                alt="calculator--v1"
                              />
                            </button>
                            <button
                              type="button"
                              className={`all_trash p-0 m-1 d-flex justify-content-center align-items-center btn ${
                                statusTrash ? "btn-primary" : "btn-danger"
                              } defaultSizeBtnTrashCalculatorDevice`}
                              style={{ width: "25px", height: "25px" }}
                              onClick={() => eventTrash(value, statusTrash)}
                            >
                              {statusTrash ? (
                                <>
                                  <img
                                    className="img-listagem-voltar"
                                    style={{
                                      filter: "invert(100%)",
                                      width: 16,
                                      height: 16,
                                    }}
                                    src="https://img.icons8.com/ios-filled/50/000000/left2.png"
                                    alt="left2"
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    className="img-listagem"
                                    width="25"
                                    height="25"
                                    src={iconelixeira}
                                    alt="delete"
                                  />
                                </>
                              )}
                            </button>
                          </div>
                        </td>
                      </tr>

                      {orderloop.map((nameOrder, indexOrder) => {
                        if (nameOrder in data[value]["main"]) {
                          return (
                            <tr className="align-center" key={indexOrder}>
                              <td className="text-start betHome">
                                <div className="d-flex flex-column fontSizeDefaultDevice">
                                  <div
                                    className={`link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover ${
                                      nameOrder === "first_home_bet"
                                        ? "pt-2 paddingTopMibile"
                                        : ""
                                    } ${statusMode ? "colorLink" : ""}`}
                                    onClick={() => {
                                      const nameBetHome =
                                        data[value]["main"][nameOrder][
                                          "name_bet_home"
                                        ].toLowerCase();
                                      let urlBetHome =
                                        data[value]["main"][nameOrder][
                                          "url_bet_home"
                                        ];

                                      openPopUpBet(urlBetHome);

                                      //window.open(urlBetHome, "_blank");
                                    }}
                                    role="button"
                                    style={{ whiteSpace: "pre" }}
                                  >
                                    {(() => {
                                      const nameBetHome =
                                        data[value]["main"][nameOrder][
                                          "name_bet_home"
                                        ].toLowerCase();

                                      // Verifique se é uma das três casas de apostas
                                      if (
                                        [
                                          "betano (br)",
                                          "bodog (en)",
                                          "vbet (lat)",
                                        ].includes(nameBetHome)
                                      ) {
                                        // Realize a substituição do nome
                                        switch (nameBetHome) {
                                          case "betano (br)":
                                            return "Betano";
                                          case "bodog (en)":
                                            return "Bodog";
                                          case "vbet (lat)":
                                            return "Vbet";
                                          default:
                                            break;
                                        }
                                      }

                                      // Se não for uma das três casas, mantenha o nome original
                                      return data[value]["main"][nameOrder][
                                        "name_bet_home"
                                      ];
                                    })()}
                                  </div>

                                  <span
                                    className={`m-0 legenda-2  ${
                                      statusMode ? "textGray1" : "text-black-50"
                                    }`}
                                  >
                                    {data[value]["main"][nameOrder]["sport"]}
                                  </span>
                                </div>
                              </td>

                              <td className="col-1 text-start">
                                <div
                                  className={`d-flex flex-column text-center justify-content-center align-items-center  ${
                                    statusMode ? "textGray2" : ""
                                  }`}
                                >
                                  <span className="fontSizeDefaultDevice">
                                    {
                                      data[value]["main"][nameOrder][
                                        "date_event"
                                      ]
                                    }
                                  </span>

                                  <span
                                    className={`badge rounded-pill ${
                                      statusMode ? "textDark2" : "text-light"
                                    } ${
                                      data[value]["main"]["bgTime"] !==
                                      undefined
                                        ? "bg-danger"
                                        : "bg-success"
                                    } fontSizeBadgeDefaultDevice`}
                                  >
                                    {
                                      data[value]["main"][nameOrder][
                                        "time_event"
                                      ]
                                    }
                                  </span>
                                </div>
                              </td>

                              <td className="text-start fontSizeDefaultDevice">
                                <div className={`d-flex flex-column `}>
                                  <div
                                    className={` text-decoration-underline link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover ${
                                      statusMode ? "colorLink" : ""
                                    }`}
                                    onClick={() => {
                                      const betHomeEnabled = JSON.parse(
                                        localStorage.listBehomeEnabled
                                      );
                                      let url =
                                        data[value]["main"][nameOrder][
                                          "url_bet"
                                        ];

                                      // Verifica se a URL contém "/en/"
                                      if (url.includes("/en/")) {
                                        // Substitui "/en/" por "/pt/"
                                        url = url.replace("/en/", "/pt/");
                                      }
                                      if (betHomeEnabled.includes("betboo")) {
                                        url = url.replace(
                                          ".sportingbet.",
                                          ".br.betboo."
                                        ); // Altere a URL para Betboo
                                      }
                                      // Verifique se a Sportingbet está habilitada nas configurações
                                      else if (
                                        betHomeEnabled.includes("sportingbet")
                                      ) {
                                        url = url.replace(
                                          ".br.betboo.",
                                          ".sportingbet."
                                        ); // Altere a URL para Sportingbet
                                      }
                                      // Verifique se a Bwin está habilitada nas configurações
                                      else if (
                                        betHomeEnabled.includes("bwin")
                                      ) {
                                        url = url.replace(
                                          ".br.betboo.",
                                          ".bwin."
                                        ); // Altere a URL para Bwin
                                      }
                                      // Verifica se a URL contém "/en/"
                                      openPopUpBet(url);

                                      //window.open(url, "_blank");
                                    }}
                                    role="button"
                                  >
                                    {
                                      data[value]["main"][nameOrder][
                                        "title_event"
                                      ]
                                    }
                                  </div>

                                  <span
                                    className={`m-0 legenda-2  ${
                                      statusMode ? "textGray1" : "text-black-50"
                                    } `}
                                  >
                                    {
                                      data[value]["main"][nameOrder][
                                        "sub_title_event"
                                      ]
                                    }
                                  </span>
                                </div>
                              </td>

                              <td className="text-start fontSizeDefaultDevice">
                                <div
                                  className={`d-flex flex-column d-flex flex-column h-100 `}
                                >
                                  <p
                                    className={`${
                                      statusMode ? "textGray2" : ""
                                    }`}
                                    dangerouslySetInnerHTML={{
                                      __html: data[value]["main"][nameOrder][
                                        "data_market"
                                      ]
                                        .replace(
                                          "<abbr",
                                          '<abbr  class=" title-abbr"  style="text-decoration:auto;"'
                                        )
                                        .replace("minorc", "legenda-2")
                                        .replace(
                                          "minor",
                                          "legenda-2 text-secondary"
                                        )
                                        .replace(
                                          "<sup",
                                          '<sup class="legenda-2"'
                                        ),
                                    }}
                                  />
                                </div>
                              </td>

                              <td className="text-start fontSizeDefaultDevice">
                                <div
                                  className={`d-flex flex-column d-flex flex-column `}
                                >
                                  <div
                                    role="button"
                                    className={`chance-main-bet link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover  ${
                                      statusMode ? "colorLink" : ""
                                    }`}
                                    onClick={() => {
                                     
                                        const betHomeEnabled = JSON.parse(
                                          localStorage.listBehomeEnabled
                                        );
                                        let url =
                                          data[value]["main"][nameOrder][
                                            "url_bet"
                                          ];
  
                                        // Verifica se a URL contém "/en/"
                                        if (url.includes("/en/")) {
                                          // Substitui "/en/" por "/pt/"
                                          url = url.replace("/en/", "/pt/");
                                        }
                                        if (betHomeEnabled.includes("betboo")) {
                                          url = url.replace(
                                            ".sportingbet.",
                                            ".br.betboo."
                                          ); // Altere a URL para Betboo
                                        }
                                        // Verifique se a Sportingbet está habilitada nas configurações
                                        else if (
                                          betHomeEnabled.includes("sportingbet")
                                        ) {
                                          url = url.replace(
                                            ".br.betboo.",
                                            ".sportingbet."
                                          ); // Altere a URL para Sportingbet
                                        }
                                        // Verifique se a Bwin está habilitada nas configurações
                                        else if (
                                          betHomeEnabled.includes("bwin")
                                        ) {
                                          url = url.replace(
                                            ".br.betboo.",
                                            ".bwin."
                                          ); // Altere a URL para Bwin
                                        }
                                        // Verifica se a URL contém "/en/"
                                        openPopUpBet(url);

                                      
                                    }}
                                    style={{ paddingTop: "2px!important" }}
                                  >
                                    {data[value]["main"][nameOrder]["chance"]}
                                  </div>
                                </div>
                              </td>

                              {nameOrder === "first_home_bet" ? (
                                <td
                                  rowSpan="3"
                                  title="Open all odds"
                                  className="text-center align-middle p-0 fontSizeDefaultDevice"
                                >
                                  {
                                    <div className="w-100 d-flex justify-content-end">
                                      <div
                                        id="openAllodds"
                                        className="rounded-start py-4 greenArbitragem openAllodds"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          handleClick(value);
                                        }}
                                      >
                                        <button>
                                          <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            strokeWidth="0"
                                            viewBox="0 0 6 16"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              width: "100%",
                                              height: "auto",
                                            }}
                                          >
                                            <path
                                              fillRule="evenodd"
                                              fill={`${
                                                statusMode ? "#0d0d0d" : "#FFF"
                                              }`}
                                              d="M0 14l6-6-6-6v12z"
                                            ></path>
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  }
                                </td>
                              ) : null}
                            </tr>
                          );
                        } else {
                          return null;
                        }
                      })}

                      <tr className="displayMobile"></tr>

                      <tr>
                        <td colSpan="6" className="text-end">
                          <div className="">
                            {data[value]["main"]["similar_event"].length > 0 ? (
                              <a
                                className={`text-decoration-none px-0 legenda-2 ${
                                  statusMode ? "colorLink" : ""
                                } fontSizeDefaultDevice`}
                                style={{ whiteSpace: "nowrap" }}
                                href={`/#/${value}`}
                              >
                                {data[value]["main"]["similar_event"]}
                              </a>
                            ) : (
                              <p
                                className={`text-decoration-none pe-1 px-0 legenda-2 fontSizeDefaultDevice displayMobile`}
                                style={{ visibility: "hidden" }}
                              >
                                XXXXXXXXXXXXXXXXXXXX
                              </p>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              ) : (
                <>
                  <style>{`
                body
                #containerFindBet{
                    margin:0;
                    padding:0;
                    background:#262626;
                    height: 600px
                  }
                  .ring{
                    line-height: 1.5!important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position:relative;
                    top:50%;
                    left:50%;
                    transform:translate(-50%,-50%);
                    width:380px;
                    height:380px;
                    background:transparent;
                    border:3px solid #3c3c3c;
                    border-radius:50%;
                    text-align:center;
                    line-height:150px;
                    font-family:sans-serif;
                    font-size:20px;
                    color:#fff000;
                    letter-spacing:4px;
                    text-transform:uppercase;
                    text-shadow:0 0 10px #fff000;
                    box-shadow:0 0 20px rgba(0,0,0,.5);
                  }
                  .ring:before{
                    content:'';
                    position:absolute;
                    top:-3px;
                    left:-3px;
                    width:100%;
                    height:100%;
                    border:3px solid transparent;
                    border-top:3px solid #fff000;
                    border-right:3px solid #fff000;
                    border-radius:50%;
                    animation:animateC 2s linear infinite;
                  }
                  #spinnerAnimation{
                    display:block;
                    position:absolute;
                    top:calc(50% - 2px);
                    left:50%;
                    width:50%;
                    height:4px;
                    background:transparent;
                    transform-origin:left;
                    animation:animate 2s linear infinite;
                  }
                  #spinnerAnimation:before{
                    content:'';
                    position:absolute;
                    width:16px;
                    height:16px;
                    border-radius:50%;
                    background:#fff000;
                    top:-6px;
                    right:-8px;
                    box-shadow:0 0 20px #fff000;
                  }
                  @keyframes animateC{
                    0%
                    {
                      transform:rotate(0deg);
                    }
                    100%
                    {
                      transform:rotate(360deg);
                    }
                  }
                  @keyframes animate{
                    0%
                    {
                      transform:rotate(45deg);
                    }
                    100%
                    {
                      transform:rotate(405deg);
                    }
                  }
                `}</style>

                  <div id="containerFindBet">
                    <div className="ring">
                      Procurando
                      <br />
                      por
                      <br />
                      apostas
                      <span id="spinnerAnimation"></span>
                    </div>
                  </div>
                </>
              )}
              {/* Fim Corpo da Tabela */}
            </Col>
          </div>
        )}
        {colunaAtiva === "eventoAoVivo" && (
          <div>
            <Col>
              {/* Inicio Calculadore/Lideira e Filtro*/}
              <div
                className="py-3 d-flex justify-content-between align-items-center"
                style={{ backgroundColor: statusMode ? "#191c1f" : "#eceff3" }}
              >
                <div className="d-flex">
                  <button
                    type="button"
                    className="h-50 mx-3 d-flex justify-content-center align-items-center btn btn-warning btn-sm fontSizeDefaultDevice"
                    onClick={() => {
                      window.open("/#/calculator/", "", "width=800,height=460");
                    }}
                  >
                    <p className="calculatorrespon">Calculadora</p>
                    <img
                      className="img-listagem"
                      width="25"
                      height="25"
                      src="https://img.icons8.com/color/48/calculator--v1.png"
                      alt="calculator--v1"
                    />
                  </button>
                  <p className="m-0"></p>

                  <button
                    type="button"
                    className={`h-50 btn ${
                      statusTrash ? "btn-primary" : "btn-danger"
                    } btn-sm`}
                  >
                    <div
                      className="d-flex justify-content-center align-items-center"
                      onClick={() => setStatusTrash(!statusTrash)}
                    >
                      {statusTrash ? (
                        <>
                          <img
                            style={{
                              filter: "invert(100%)",
                              width: 25,
                              height: 25,
                            }}
                            src="https://img.icons8.com/ios-filled/50/000000/left2.png"
                            alt="left2"
                          />
                          <p className="m-0 fontSizeDefaultDevice">
                            Voltar aos jogos
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="m-0 fontSizeDefaultDevice calculatorrespon">
                            Lixeira
                          </p>
                          <img
                            width="27"
                            height="27"
                            src={iconelixeira}
                            alt="delete"
                          />
                        </>
                      )}
                    </div>
                  </button>

                  <button
                    id="back_game"
                    type="button"
                    className="h-50 btn btn-sm btn-primary d-none"
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <img
                        style={{
                          filter: "invert(100%)",
                          width: 25,
                          height: 25,
                        }}
                        src="https://img.icons8.com/ios-filled/50/000000/left2.png"
                        alt="left2"
                      />
                      <p className="m-0">Voltar aos jogos</p>
                    </div>
                  </button>
                </div>

                <div className="me-3">
                  <Suspense fallback={<div>Loading...</div>}>
                    <FilterMobile
                      statusMode={statusMode}
                      controllerLocalStorage={controllerLocalStorage}
                      valueMin={valueMin}
                      setValueMin={setValueMin}
                      valueMax={valueMax}
                      setValueMax={setValueMax}
                      betHome={betHome}
                      setBetHome={setBetHome}
                      quantityBetHomeEnabled={quantityBetHomeEnabled}
                      setQuantityBetHomeEnabled={setQuantityBetHomeEnabled}
                      sports={sports}
                      setSports={setSports}
                      quantitySportsEnabled={quantitySportsEnabled}
                      setQuantitySportsEnabled={setQuantitySportsEnabled}
                      timeEvent={timeEvent}
                      setTimeEvent={setTimeEvent}
                    />
                  </Suspense>
                </div>
              </div>
              {/* Fim Calculadore/Lideira e Filtro*/}
              {/* Inicio Corpo da Tabela */}
              {Object.keys(data).length > 0 || statusTrash ? (
                <div>
                  <table className="w-100">
                    <thead className="">
                      <tr className=" greenArbitragem py-1">
                        <th
                          className={`fw-bold fontSizeDefaultDevice text-center ${
                            statusMode ? "textDark" : "text-light"
                          } p-0 profitDesktop`}
                          colSpan={2}
                        >
                          Lucro
                        </th>
                        <th
                          className={`fw-bold fontSizeDefaultDevice text-center ${
                            statusMode ? "textDark" : "text-light"
                          } p-0 profitMobile`}
                        >
                          Lucro
                        </th>
                        <th
                          className={`fw-bold fontSizeDefaultDevice text-start ${
                            statusMode ? "textDark" : "text-light"
                          } betHome profitDesktop`}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Casa de aposta
                        </th>
                        <th
                          className={`fw-bold fontSizeDefaultDevice text-center ${
                            statusMode ? "textDark" : "text-light"
                          } p-0`}
                        >
                          Data
                        </th>
                        <th
                          className={`fw-bold fontSizeDefaultDevice text-start ${
                            statusMode ? "textDark" : "text-light"
                          } p-0`}
                        >
                          Evento
                        </th>
                        <th
                          className={`fw-bold fontSizeDefaultDevice text-start ${
                            statusMode ? "textDark" : "text-light"
                          } p-0`}
                        >
                          Mercado
                        </th>
                        <th
                          className={`fw-bold fontSizeDefaultDevice text-start ${
                            statusMode ? "textDark" : "text-light"
                          } p-0`}
                        >
                          Chance
                        </th>
                        <th
                          className={`fw-bold fontSizeDefaultDevice text-start ${
                            statusMode ? "textDark" : "text-light"
                          } p-0`}
                        ></th>
                      </tr>
                    </thead>

                    {Object.keys(data).map((value, index) => (
                      <tbody
                        className={`w-100 ${statusMode ? "bgDark" : ""}`}
                        key={index}
                      ></tbody>
                    ))}
                    {/* Elemento de aviso posicionado absolutamente */}
                  </table>
                  <div className="alert alert-info">
                    <i className="fas fa-cog fa-spin me-2"></i>Em Construção...
                  </div>
                </div>
              ) : (
                <>
                  <style>{`
                body
                #containerFindBet{
                    margin:0;
                    padding:0;
                    background:#262626;
                    height: 600px
                  }
                  .ring{
                    line-height: 1.5!important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position:relative;
                    top:50%;
                    left:50%;
                    transform:translate(-50%,-50%);
                    width:380px;
                    height:380px;
                    background:transparent;
                    border:3px solid #3c3c3c;
                    border-radius:50%;
                    text-align:center;
                    line-height:150px;
                    font-family:sans-serif;
                    font-size:20px;
                    color:#fff000;
                    letter-spacing:4px;
                    text-transform:uppercase;
                    text-shadow:0 0 10px #fff000;
                    box-shadow:0 0 20px rgba(0,0,0,.5);
                  }
                  .ring:before{
                    content:'';
                    position:absolute;
                    top:-3px;
                    left:-3px;
                    width:100%;
                    height:100%;
                    border:3px solid transparent;
                    border-top:3px solid #fff000;
                    border-right:3px solid #fff000;
                    border-radius:50%;
                    animation:animateC 2s linear infinite;
                  }
                  #spinnerAnimation{
                    display:block;
                    position:absolute;
                    top:calc(50% - 2px);
                    left:50%;
                    width:50%;
                    height:4px;
                    background:transparent;
                    transform-origin:left;
                    animation:animate 2s linear infinite;
                  }
                  #spinnerAnimation:before{
                    content:'';
                    position:absolute;
                    width:16px;
                    height:16px;
                    border-radius:50%;
                    background:#fff000;
                    top:-6px;
                    right:-8px;
                    box-shadow:0 0 20px #fff000;
                  }
                  @keyframes animateC{
                    0%
                    {
                      transform:rotate(0deg);
                    }
                    100%
                    {
                      transform:rotate(360deg);
                    }
                  }
                  @keyframes animate{
                    0%
                    {
                      transform:rotate(45deg);
                    }
                    100%
                    {
                      transform:rotate(405deg);
                    }
                  }
                `}</style>

                  <div id="containerFindBet">
                    <div className="ring">
                      Procurando
                      <br />
                      por
                      <br />
                      apostas
                      <span id="spinnerAnimation"></span>
                    </div>
                  </div>
                </>
              )}
              {/* Fim Corpo da Tabela */}
            </Col>
          </div>
        )}
      </Row>
    </div>
  );
}

export default MainBets;
