import React, { useState, useEffect, lazy, Suspense } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { format } from "date-fns";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";

// Importe seus componentes

//import HeaderCalculator from "./components/HeadrCalculator";
//import HeaderWebsite from "./components/HeaderWebsite";
import MainBets from "./components/MainBets";
//import FilterSurebet from "./components/Filter";
//import Calculator from "./components/Calculator";
//import HideSurebet from "./components/HideSurebet";
//import EmptyCalculator from "./components/EmptyCalculator";
//import AuthenticationForm from "./components/AuthenticationForm";
//import SubNavbar from "./components/SubNavbar";
//import LandingPage from "./components/LandingPage";
//import Pagamentos from "./components/PageDeCompraDoPlano";
//import Afilhado from "./components/Afilhado";
//import DashboardControl from "./components/DashboardControl";
//import Settings from "./components/Settings";
//import ConfigPage from "./components/ConfigPage";
//import AfiliadoLink from "./components/AfiliadoLink";
//import HeaderPagamentos from "./components/HeaderPagamentos";

// Importe seus hooks e funções
import { FilterHead } from "./hooks/FilterHead";
const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");

 
  useEffect(() => {
    function clearCacheAndCookies() {
      window.location.reload(); // Recarrega a página, limpando o cache e cookies
    }

    clearCacheAndCookies(); // Chama a função uma vez quando a página é carregada

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");
    axios
      .get(
        `http://158.69.157.170:8080/session-status?session_id=${sessionId}`,
        {}
      )
      .then(function (response) {
        console.log(response);
        setStatus(response.data.status);
        console.log("data.status", response.data.status);
        setCustomerEmail(response.data.customer_email);
        console.log("data.customer_email", response.data.customer_email);
      })

      .catch((error) => {
        console.error("Erro ao criar a assinatura:", error);
        // Lida com erros, se houver
      });
  }, []);

  if (status === "open") {
    return <Navigate to="/checkout" />;
  }

  if (status === "complete") {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to{" "}
          {customerEmail}. If you have any questions, please email
          <a href="mailto:orders@example.com">orders@example.com</a>.
        </p>
      </section>
    );
  }

  return null;
};

const HeaderCalculator = lazy(() => import("./components/HeadrCalculator"));
const HeaderWebsite = lazy(() => import("./components/HeaderWebsite"));
//const MainBets = lazy(() => import("./components/MainBets"));
const FilterSurebet = lazy(() => import("./components/Filter"));
const Calculator = lazy(() => import("./components/Calculator"));
const HideSurebet = lazy(() => import("./components/HideSurebet"));
const EmptyCalculator = lazy(() => import("./components/EmptyCalculator"));
const AuthenticationForm = lazy(() =>
  import("./components/AuthenticationForm")
);
const SubNavbar = lazy(() => import("./components/SubNavbar"));
const LandingPage = lazy(() => import("./components/LandingPage"));
const Pagamentos = lazy(() => import("./components/PageDeCompraDoPlano"));
const Renovacao = lazy(() => import("./components/PageCompraRenovacao"));
const Afilhado = lazy(() => import("./components/Afilhado"));
const DashboardControl = lazy(() => import("./components/DashboardControl"));
const Settings = lazy(() => import("./components/Settings"));
const ConfigPage = lazy(() => import("./components/ConfigPage"));
const AfiliadoLink = lazy(() => import("./components/AfiliadoLink"));
const HeaderPagamentos = lazy(() => import("./components/HeaderPagamentos"));
const Sobrenos = lazy(()=> import("./components/sobrenos"));
const Termoscondicoes = lazy(()=> import("./components/Termoscodincoes"));
function App() {
  const location = useLocation();
  const [userPlan, setUserPlan] = useState("free");
  const [linkCopied, setLinkCopied] = useState(false);
  const [generatedCode, setGeneratedCode] = useState("");
  const [popupWindow,setPopupWindow] = useState(null);
  const [popuwindowsBet,setPopupWindowBet] = useState(null);
  const handleCopyLink = () => {
    setLinkCopied(true);
  };

  const handleGenerateUniqueCode = (code) => {
    // Esta função será chamada quando o código único for gerado
    setGeneratedCode(code);

    // Você também pode enviar o código ao servidor a partir daqui, se necessário.
    // onGenerateUniqueCode(code);
  };

  const controllerLocalStorage = FilterHead();
  controllerLocalStorage["readLocalstorage"]();
  const [fullData, setFullData] = useState([]);
  const [userData, setUserData] = useState(null);
  const [valueMin, setValueMin] = useState(
    JSON.parse(localStorage["profitMin"])
  );
  
 
  const [valueMax, setValueMax] = useState(
    JSON.parse(localStorage["profitMax"])
  );
  const [betHome, setBetHome] = useState(
    JSON.parse(localStorage["listBehomeEnabled"])
  );
  const [sports, setSports] = useState(
    JSON.parse(localStorage["listSportsEnabled"])
  );
  const [quantitySportsEnabled, setQuantitySportsEnabled] = useState(
    JSON.parse(localStorage["listSportsEnabled"]).length
  );
  const [quantityBetHomeEnabled, setQuantityBetHomeEnabled] = useState(
    JSON.parse(localStorage["listBehomeEnabled"]).length
  );
  const [timeEvent, setTimeEvent] = useState(JSON.parse(localStorage["time"]));
  const [itemsTrash, setItemsTrash] = useState(
    JSON.parse(localStorage["trash"])
  );
  const [statusMode, setStatusMode] = useState(
    JSON.parse(localStorage["statusMode"])
  );

  const [login, setLogin] = useState(
    JSON.parse(localStorage.getItem("login")) || false
  );
  const showLogoutButton = location.pathname === "/";
  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(localStorage.getItem("login")) || false
  );

  // Verifique se a URL atual contém "/calculator/"
  const isCalculatorPage = location.pathname.includes("/calculator/");
  // Verifique se a URL atual começa com "/calculator/"
  const isCalculatorPages = location.pathname.startsWith("/calculator/");

  const [notificationTime, setNotificationTime] = useState(null);
  const [testingNotification, setTestingNotification] = useState(false); // Estado para controle do teste de notificação

  // Crie um novo estado para controlar o volume da notificação
  const [notificationVolume, setNotificationVolume] = useState(0.5);

  const handleLogout = () => {
    // Limpe o estado de login e remova-o do armazenamento local
    setLogin(false);
    setIsLoggedIn(false);
    localStorage.removeItem("login");
  };

  // Dentro da função handleLoginSuccess
  const handleLoginSuccess = (isLoggedIn, userData) => {
    setIsLoggedIn(isLoggedIn);
    setUserData(userData);
    setUserPlan(userData.plano); // Defina o plano do usuário com base nos dados do usuário
  };
  function clearCacheAndCookies() {
      window.location.reload(); // Recarrega a página, limpando o cache e cookies
    }
  const FectDataC = async()=>{
    
   
    const response = await axios.get("https://arbitragem.vps.webdock.cloud/safe_bet");
    
    localStorage["dataCalculator"] = JSON.stringify(response.data);
  };
  useEffect(() => {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
    
    FectDataC();
   
    setInterval(() => {
    
      FectDataC();
    }, 1500);
   
    
  }, []);

  useEffect(() => {
    statusMode
      ? (document.body.style.backgroundColor = "#0d0d0d")
      : (document.body.style.backgroundColor = "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      {isLoggedIn === false ? (
        <div>
          {isLoggedIn === false &&
          location.pathname !== "/logar" &&
          location.pathname !== "/afiliado" &&
          location.pathname !== "/quesomos" &&
          location.pathname !== "/termoscondicoes" &&
          location.pathname !== "/curso" &&
          location.pathname !== "/calculator/" &&
          location.pathname !== "/calculator/*" &&
          location.pathname !== "/calculator/:id" &&
          !isCalculatorPage &&
          !isCalculatorPages ? (
            <div>
              <Suspense fallback={<div>Loading...</div>}>
                <SubNavbar
                  statusMode={statusMode}
                  setStatusMode={setStatusMode}
                  onLogout={handleLogout}
                  isLoggedIn={isLoggedIn}
                  showLogoutButton={showLogoutButton}
                  handleLoginSuccess={handleLoginSuccess}
                />
                
              </Suspense>
            </div>
          ) : null}
          {isLoggedIn === false ? (
            <>
              <Routes>
                <Route
                  path="/logar"
                  element={
                    <Suspense fallback={<div>Loading...</div>}>
                      <AuthenticationForm
                        statusMode={statusMode}
                        onLoginSuccess={handleLoginSuccess}
                      />
                    </Suspense>
                  }
                />
                <Route
                  path="/afiliado"
                  element={
                    <Suspense fallback={<div>Loading...</div>}>
                      <Afilhado />
                    </Suspense>
                  }
                />
                <Route
                  path="/curso"
                  element={
                    <Suspense fallback={<div>Loading...</div>}>
                      <LandingPage />
                    </Suspense>
                  }
                />
                <Route
                  path="/quesomos"
                  element={
                    <Suspense fallback={<div>Loading...</div>}>
                      <Sobrenos />
                    </Suspense>
                  }
                />
                <Route
                  path="/termoscondicoes"
                  element={
                    <Suspense fallback={<div>Loading...</div>}>
                      <Termoscondicoes />
                    </Suspense>
                  }
                />
                <Route
                  path={"/calculator"}
                  element={
                    <>
                      <Suspense fallback={<div>Loading...</div>}>
                        <HeaderCalculator
                          statusMode={statusMode}
                          setStatusMode={setStatusMode}
                          onLogout={handleLogout}
                          isLoggedIn={isLoggedIn}
                          showLogoutButton={showLogoutButton}
                        />
                      </Suspense>
                      <Suspense fallback={<div>Loading...</div>}>
                        <EmptyCalculator
                          statusMode={statusMode}
                          isLoggedIn={isLoggedIn}
                        />
                      </Suspense>
                    </>
                  }
                />

                <Route
                  path={"/calculator/:id"}
                  element={
                    <>
                      <Suspense fallback={<div>Loading...</div>}>
                        <HeaderCalculator
                          statusMode={statusMode}
                          setStatusMode={setStatusMode}
                          onLogout={handleLogout} // Passe a função de logout para o componente HeaderWebsite
                          isLoggedIn={isLoggedIn}
                          showLogoutButton={showLogoutButton}
                        />
                      </Suspense>
                      <Suspense fallback={<div>Loading...</div>}>
                        <Calculator
                          statusMode={statusMode}
                          isLoggedIn={isLoggedIn}
                        />{" "}
                      </Suspense>
                    </>
                  }
                />

                <Route
                  path={"/calculator/:id/:id2"}
                  element={
                    <>
                      <Suspense fallback={<div>Loading...</div>}>
                        <HeaderCalculator
                          statusMode={statusMode}
                          setStatusMode={setStatusMode}
                          onLogout={handleLogout} // Passe a função de logout para o componente HeaderWebsite
                          isLoggedIn={isLoggedIn}
                          showLogoutButton={showLogoutButton}
                        />
                      </Suspense>
                      <Suspense fallback={<div>Loading...</div>}>
                        <Calculator
                          statusMode={statusMode}
                          isLoggedIn={isLoggedIn}
                        />{" "}
                      </Suspense>
                    </>
                  }
                />
              </Routes>
            </>
          ) : null}
        </div>
      ) : (
        <>
          {userPlan === "free" || userPlan === "Gratuito" ? (
            <Routes>
              <Route
                path="/plano"
                element={
                  <>
                    <Suspense fallback={<div>Loading...</div>}>
                      <HeaderPagamentos userData={userData} user={userData} />
                    </Suspense>
                  </>
                }
              />
            </Routes>
          ) : (
            <>
              <>
                <Suspense fallback={<div>Loading...</div>}>
                  <HeaderWebsite
                    statusMode={statusMode}
                    setStatusMode={setStatusMode}
                    onLogout={handleLogout} // Passe a função de logout para o componente HeaderWebsite
                    isLoggedIn={isLoggedIn}
                    showLogoutButton={showLogoutButton}
                    notificationVolume={notificationVolume}
                    setNotificationVolume={setNotificationVolume}
                    userData={userData}
                    user={userData}
                  />
                </Suspense>
                <Routes>
                  <Route
                    path={"/"}
                    element={
                      <div className="d-flex">
                        
                          <MainBets
                            valueMin={valueMin}
                            valueMax={valueMax}
                            betHome={betHome}
                            sports={sports}
                            fullData={fullData}
                            setFullData={setFullData}
                            timeEvent={timeEvent}
                            itemsTrash={itemsTrash}
                            setItemsTrash={setItemsTrash}
                            statusMode={statusMode}
                            controllerLocalStorage={controllerLocalStorage}
                            setValueMin={setValueMin}
                            setValueMax={setValueMax}
                            setBetHome={setBetHome}
                            quantityBetHomeEnabled={quantityBetHomeEnabled}
                            setQuantityBetHomeEnabled={
                              setQuantityBetHomeEnabled
                            }
                            setSports={setSports}
                            quantitySportsEnabled={quantitySportsEnabled}
                            setQuantitySportsEnabled={setQuantitySportsEnabled}
                            setTimeEvent={setTimeEvent}
                            setStatusMode={setStatusMode}
                            onLogout={handleLogout} // Passe a função de logout para o componente HeaderWebsite
                            isLoggedIn={isLoggedIn}
                            showLogoutButton={showLogoutButton}
                            notificationVolume={notificationVolume}
                            setNotificationVolume={setNotificationVolume}
                            setPopupWindow={setPopupWindow}
                            popupWindow={popupWindow}
                            setPopupWindowBet={setPopupWindowBet}
                            popuwindowsBet={popuwindowsBet}
                          />
                        
                        <Suspense fallback={<div>Loading...</div>}>
                          <FilterSurebet
                            statusMode={statusMode}
                            controllerLocalStorage={controllerLocalStorage}
                            valueMin={valueMin}
                            setValueMin={setValueMin}
                            valueMax={valueMax}
                            setValueMax={setValueMax}
                            betHome={betHome}
                            setBetHome={setBetHome}
                            quantityBetHomeEnabled={quantityBetHomeEnabled}
                            setQuantityBetHomeEnabled={
                              setQuantityBetHomeEnabled
                            }
                            sports={sports}
                            setSports={setSports}
                            quantitySportsEnabled={quantitySportsEnabled}
                            setQuantitySportsEnabled={setQuantitySportsEnabled}
                            timeEvent={timeEvent}
                            setTimeEvent={setTimeEvent}
                          />
                        </Suspense>
                      </div>
                    }
                  />

                  <Route
                    path={"/:id"}
                    element={
                      <div className="d-flex">
                        <Suspense fallback={<div>Loading...</div>}>
                          <HideSurebet
                            valueMin={valueMin}
                            valueMax={valueMax}
                            betHome={betHome}
                            sports={sports}
                            fullData={fullData}
                            setFullData={setFullData}
                            timeEvent={timeEvent}
                            itemsTrash={itemsTrash}
                            setItemsTrash={setItemsTrash}
                            statusMode={statusMode}
                            controllerLocalStorage={controllerLocalStorage}
                            setValueMin={setValueMin}
                            setValueMax={setValueMax}
                            setBetHome={setBetHome}
                            quantityBetHomeEnabled={quantityBetHomeEnabled}
                            setQuantityBetHomeEnabled={
                              setQuantityBetHomeEnabled
                            }
                            setSports={setSports}
                            quantitySportsEnabled={quantitySportsEnabled}
                            setQuantitySportsEnabled={setQuantitySportsEnabled}
                            setTimeEvent={setTimeEvent}
                            setPopupWindow={setPopupWindow}
                            setPopupWindowBet={setPopupWindowBet}
                            popupWindow={popupWindow}
                            popuwindowsBet={popuwindowsBet}
                          />
                        </Suspense>
                        <Suspense fallback={<div>Loading...</div>}>
                          <FilterSurebet
                            statusMode={statusMode}
                            controllerLocalStorage={controllerLocalStorage}
                            valueMin={valueMin}
                            setValueMin={setValueMin}
                            valueMax={valueMax}
                            setValueMax={setValueMax}
                            betHome={betHome}
                            setBetHome={setBetHome}
                            quantityBetHomeEnabled={quantityBetHomeEnabled}
                            setQuantityBetHomeEnabled={
                              setQuantityBetHomeEnabled
                            }
                            sports={sports}
                            setSports={setSports}
                            quantitySportsEnabled={quantitySportsEnabled}
                            setQuantitySportsEnabled={setQuantitySportsEnabled}
                            timeEvent={timeEvent}
                            setTimeEvent={setTimeEvent}
                          />
                        </Suspense>
                      </div>
                    }
                  />
                  <Route
                    path={"/calculator"}
                    element={
                      <Suspense fallback={<div>Loading...</div>}>
                        <EmptyCalculator
                          statusMode={statusMode}
                          isLoggedIn={isLoggedIn}
                        />
                      </Suspense>
                    }
                  />

                  <Route
                    path={"/calculator/:id"}
                    element={
                      <Suspense fallback={<div>Loading...</div>}>
                        <Calculator
                          statusMode={statusMode}
                          isLoggedIn={isLoggedIn}
                          
                        />
                      </Suspense>
                    }
                  />

                  <Route
                    path={"/calculator/:id/:id2"}
                    element={
                      <Suspense fallback={<div>Loading...</div>}>
                        <Calculator
                          statusMode={statusMode}
                          isLoggedIn={isLoggedIn}
                        />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <Suspense fallback={<div>Loading...</div>}>
                        <DashboardControl
                          statusMode={statusMode}
                          setStatusMode={setStatusMode}
                          isLoggedIn={isLoggedIn}
                        />
                      </Suspense>
                    }
                  />

                  <Route
                    path="/config"
                    element={
                      <>
                        <Suspense fallback={<div>Loading...</div>}>
                          <ConfigPage
                            statusMode={statusMode}
                            setStatusMode={setStatusMode}
                            handleLogout={handleLogout}
                            isLoggedIn={isLoggedIn}
                            setIsLoggedIn={setIsLoggedIn}
                            userData={userData}
                            user={userData}
                          />
                        </Suspense>
                      </>
                    }
                  />
                  <Route
                    path="/gerarlink"
                    element={
                      <>
                        <Suspense fallback={<div>Loading...</div>}>
                          <AfiliadoLink
                            onCopyLink={handleCopyLink}
                            onGenerateUniqueCode={handleGenerateUniqueCode}
                            user={userData}
                          />
                          {linkCopied && (
                            <p>Link copiado para a área de transferência!</p>
                          )}
                        </Suspense>
                      </>
                    }
                  />
                  <Route
                    path="/cursos"
                    element={
                      <Suspense fallback={<div>Loading...</div>}>
                        <LandingPage />
                      </Suspense>
                    }
                  />
                  <Route
                  path="/renovacao"
                  element={
                    <Suspense fallback={<div>Loading...</div>}>
                      <Renovacao
                       userData={userData}
                       user={userData}
                       />
                    </Suspense>
                  }
                  />
                  <Route
                    path="/session-status"
                    element={
                      <Suspense fallback={<div>Loading...</div>}>
                        <Return />
                      </Suspense>
                    }
                  />
                </Routes>
              </>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default App;
